import LogoBifiDark from 'icons/dapps/logo-bifi-dark.png';
import LogoBifiX from 'icons/dapps/logo-bifi-x.png';
import LogoBifi from 'icons/dapps/logo-bifi.png';
import LogoBifiXDark from 'icons/dapps/logo-bifix-dark.png';
import LogoBtcfiDark from 'icons/dapps/logo-btcfi-dark.png';
import LogoBtcfi from 'icons/dapps/logo-btcfi.png';
import LogoCoin98 from 'icons/dapps/logo-coin98.png';
import LogoCoinbase from 'icons/dapps/logo-coinbase.png';
import LogoCrossChainSwapDark from 'icons/dapps/logo-crosschainswap-dark.png';
import LogoCrossChainSwap from 'icons/dapps/logo-crosschainswap.png';
import LogoEverderx from 'icons/dapps/logo-everdex.png';
import LogoHardhat from 'icons/dapps/logo-hardhat.png';
import LogoMetamask from 'icons/dapps/logo-metamask.png';
import LogoOwly from 'icons/dapps/logo-owly.png';
import LogoPockie from 'icons/dapps/logo-pockie.png';
import LogoSac from 'icons/dapps/logo-sac.png';
import LogoTenderly from 'icons/dapps/logo-tenderly.png';
import LogoTruffle from 'icons/dapps/logo-truffle.png';
import LogoWitchworld from 'icons/dapps/logo-witchworld.png';
import LogoNectar from 'icons/dapps/nectarx.png';
import LogoThepol from 'icons/dapps/logo-thepol.png';
import LogoThepolDark from 'icons/dapps/logo-thepol-dark.png';
import LogoBiquid from 'icons/dapps/logo-biquid.png';
import LogoBiquidDark from 'icons/dapps/logo-biquid-dark.png';

import type { MarketplaceAppOverview } from '../types/client/marketplace';

export const dAppsList: Array<MarketplaceAppOverview> = [
  {
    id: 'bifi',
    external: true,
    title: 'BiFi',
    logo: LogoBifiDark,
    logoDarkMode: LogoBifi,
    shortDescription:
      'BiFi is a multichain lending, staking, and liquidity pool mining platform that offers the first decentralized Bitcoin lending.',
    categories: ['dapps/'],
    url: 'https://www.bifi.finance/ko',
    author: '',
    description:
      'BiFi is a multichain lending, staking, and liquidity pool mining platform that offers the first decentralized Bitcoin lending.',
    site: 'https://www.bifi.finance/ko',
    twitter: '',
    telegram: '',
    github: '',
  },
  {
    id: 'bifix',
    external: true,
    title: 'BiFi X',
    logo: LogoBifiXDark,
    logoDarkMode: LogoBifiX,
    shortDescription:
      'BiFi X is a one-of-a-kind DeFi service using flash loans on yield farming and margin trading positions.',
    categories: ['dapps/'],
    url: 'https://x.bifi.finance/',
    author: '',
    description:
      'BiFi X is a one-of-a-kind DeFi service using flash loans on yield farming and margin trading positions.',
    site: 'https://x.bifi.finance/',
    twitter: '',
    telegram: '',
    github: '',
  },
  {
    id: 'btcfi',
    external: true,
    title: 'BTCFi',
    logo: LogoBtcfiDark,
    logoDarkMode: LogoBtcfi,
    shortDescription:
      'BTCFi is a CDP protocol that allows Bitcoin holders to use their BTC to mint BtcUSD, opening up new possibilities of asset utilization.',
    categories: ['dapps/'],
    url: 'https://www.btcfi.one/',
    author: '',
    description:
      'BTCFi is a CDP protocol that allows Bitcoin holders to use their BTC to mint BtcUSD, opening up new possibilities of asset utilization.',
    site: 'https://www.btcfi.one/',
    twitter: '',
    telegram: '',
    github: '',
  },
  {
    id: 'sac',
    external: true,
    title: 'Super Athletes Club',
    logo: LogoSac,
    logoDarkMode: LogoSac,
    shortDescription:
      'Super Athletes Club is building sports metaverse with various fashion brands.',
    categories: ['dapps/'],
    url: 'https://sac-nft.com/',
    author: '',
    description:
      'Super Athletes Club is building sports metaverse with various fashion brands.',
    site: 'https://sac-nft.com/',
    twitter: '',
    telegram: '',
    github: '',
  },
  {
    id: 'pockie',
    external: true,
    title: 'Pockie',
    logo: LogoPockie,
    logoDarkMode: LogoPockie,
    shortDescription:
      'Discover Untapped opportunities with Pockie wallet, your wealth controller',
    categories: ['dapps/'],
    url: 'https://www.pockie.io/',
    author: '',
    description:
      'Discover Untapped opportunities with Pockie wallet, your wealth controller',
    site: 'https://www.pockie.io/',
    twitter: '',
    telegram: '',
    github: '',
  },
  {
    id: 'everdex',
    external: true,
    title: 'Everdex',
    logo: LogoEverderx,
    logoDarkMode: LogoEverderx,
    shortDescription:
      'The DEX that gets the job done, fast and efficiently. Simple to use, with low fees',
    categories: ['dapps/'],
    url: 'https://www.everdex.app/',
    author: '',
    description:
      'The DEX that gets the job done, fast and efficiently. Simple to use, with low fees',
    site: 'https://www.everdex.app/',
    twitter: '',
    telegram: '',
    github: '',
  },
  {
    id: 'witchworld',
    external: true,
    title: 'Witchworld',
    logo: LogoWitchworld,
    logoDarkMode: LogoWitchworld,
    shortDescription:
      'Enjoy various fun spaces such as the NFT Exhibition Hall and the PFP community and create your own space.',
    categories: ['dapps/'],
    url: 'https://witchworld.io/',
    author: '',
    description:
      'Enjoy various fun spaces such as the NFT Exhibition Hall and the PFP community and create your own space.',
    site: 'https://witchworld.io/',
    twitter: '',
    telegram: '',
    github: '',
  },
  {
    id: 'nectar',
    external: true,
    title: 'Nectar',
    logo: LogoNectar,
    logoDarkMode: LogoNectar,
    shortDescription:
      'Nectar is an innovative multi-chain spot and perpetual order book DEX with features for non-custodial fundraising.',
    categories: ['dapps/'],
    url: 'https://nectarx.io',
    author: '',
    description:
      'Nectar is an innovative multi-chain spot and perpetual order book DEX with features for non-custodial fundraising.',
    site: 'https://nectarx.io',
    twitter: '',
    telegram: '',
    github: '',
  },
  {
    id: 'crosschainswap',
    external: true,
    title: 'CrossChainSwap',
    logo: LogoCrossChainSwapDark,
    logoDarkMode: LogoCrossChainSwap,
    shortDescription:
      'A powerful multichain aggregator that can search through various services to find the best rates in the cryotisphere.',
    categories: ['dapps/'],
    url: 'https://crosschain-swap.com/',
    author: '',
    description:
      'A powerful multichain aggregator that can search through various services to find the best rates in the cryotisphere.',
    site: 'https://crosschain-swap.com/',
    twitter: '',
    telegram: '',
    github: '',
  },
  {
    id: 'trufflesuite',
    external: true,
    title: 'Truffle Suite',
    logo: LogoTruffle,
    logoDarkMode: LogoTruffle,
    shortDescription:
      'The most comprehensive suite fof tools for smart contract development.',
    categories: ['dapps/'],
    url: 'https://archive.trufflesuite.com/',
    author: '',
    description:
      'The most comprehensive suite fof tools for smart contract development.',
    site: 'https://archive.trufflesuite.com/',
    twitter: '',
    telegram: '',
    github: '',
  },
  {
    id: 'hardhat',
    external: true,
    title: 'hardhat',
    logo: LogoHardhat,
    logoDarkMode: LogoHardhat,
    shortDescription: 'Ethereum development environment for professionals.',
    categories: ['dapps/'],
    url: 'https://hardhat.org/',
    author: '',
    description: 'Ethereum development environment for professionals.',
    site: 'https://hardhat.org/',
    twitter: '',
    telegram: '',
    github: '',
  },
  {
    id: 'tenderly',
    external: true,
    title: 'tenderly',
    logo: LogoTenderly,
    logoDarkMode: LogoTenderly,
    shortDescription:
      'Enabling Web3 developers to build, test, monitor, and operate smart contracts from their inceptions to mass adoption.',
    categories: ['dapps/'],
    url: 'https://tenderly.co/',
    author: '',
    description:
      'Enabling Web3 developers to build, test, monitor, and operate smart contracts from their inceptions to mass adoption.',
    site: 'https://tenderly.co/',
    twitter: '',
    telegram: '',
    github: '',
  },
  {
    id: 'metamask',
    external: true,
    title: 'MetaMask',
    logo: LogoMetamask,
    logoDarkMode: LogoMetamask,
    shortDescription: 'A crypto wallet & gateway to blockchian apps',
    categories: ['dapps/'],
    url: 'https://metamask.io/',
    author: '',
    description: 'A crypto wallet & gateway to blockchian apps',
    site: 'https://metamask.io/',
    twitter: '',
    telegram: '',
    github: '',
  },
  {
    id: 'coinbase',
    external: true,
    title: 'Coinbase Wallet',
    logo: LogoCoinbase,
    logoDarkMode: LogoCoinbase,
    shortDescription: 'Your key to the world of crypto',
    categories: ['dapps/'],
    url: 'https://www.coinbase.com/wallet',
    author: '',
    description: 'Your key to the world of crypto',
    site: 'https://www.coinbase.com/wallet',
    twitter: '',
    telegram: '',
    github: '',
  },
  {
    id: 'coin98',
    external: true,
    title: 'Coin98',
    logo: LogoCoin98,
    logoDarkMode: LogoCoin98,
    shortDescription: 'Store Multi-chain Cryptoassets',
    categories: ['dapps/'],
    url: 'https://coin98.com/',
    author: '',
    description: 'Store Multi-chain Cryptoassets',
    site: 'https://coin98.com/',
    twitter: '',
    telegram: '',
    github: '',
  },
  {
    id: 'thepol',
    external: true,
    title: 'THEPOL',
    logo: LogoThepol,
    logoDarkMode: LogoThepolDark,
    shortDescription:
      'THEPOL is a blockchain-based survey reward app where users can earn BFC',
    categories: ['dapps/'],
    url: 'https://www.thepol.com',
    author: '',
    description:
      'THEPOL is a blockchain-based survey reward app where users can earn BFC',
    site: 'https://www.thepol.com',
    twitter: '',
    telegram: '',
    github: '',
  },
  {
    id: 'biquid',
    external: true,
    title: 'Biquid',
    logo: LogoBiquid,
    logoDarkMode: LogoBiquidDark,
    shortDescription:
      'Biquid is a Liquid Staking service that lets you stake BFC to earn rewards with (w)stBFC, usable in various DeFi protocols.',
    categories: ['dapps/'],
    url: 'https://biquid.io',
    author: '',
    description:
      'Biquid is a Liquid Staking service that lets you stake BFC to earn rewards with (w)stBFC, usable in various DeFi protocols.',
    site: 'https://biquid.io',
    twitter: '',
    telegram: '',
    github: '',
  },
];

export default dAppsList;
