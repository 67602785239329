import { getEnvValue } from './utils';

const OG_IMAGE_URL =
  'https://explorer.mainnet.bifrostnetwork.com/static/og_placeholder.png';

const meta = Object.freeze({
  promoteBlockscoutInTitle:
    getEnvValue('NEXT_PUBLIC_PROMOTE_BLOCKSCOUT_IN_TITLE') || 'true',
  og: {
    description: getEnvValue('NEXT_PUBLIC_OG_DESCRIPTION') || '',
    imageUrl: OG_IMAGE_URL,
  },
});

export default meta;
